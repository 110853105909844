<template>
  <div>
    <el-form inline label-width="auto" size="small">
      <el-form-item :label="$t('title.Ticketno')">
        <el-input
          v-model="form.workNumber"
          :placeholder="$t('page.inputPlaceholder')"
          @keyup.native.enter="handleQuery"
        />
      </el-form-item>
      <el-form-item :label="$t('title.PlatformAccount')">
        <el-select
          v-model="form.bg"
          :placeholder="$t('page.selectPlaceholder')"
          clearable
          filterable
          multiple
        >
          <el-option
            v-for="item in platFormAccountList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('title.OrderCreationMethod')">
        <el-select
          v-model="form.workType"
          :placeholder="$t('page.selectPlaceholder')"
          clearable
          filterable
        >
          <el-option
            v-for="item in workTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('title.SentStatus')">
        <el-select
          v-model="form.workStatus"
          :placeholder="$t('page.selectPlaceholder')"
          clearable
          filterable
          multiple
        >
          <el-option
            v-for="item in workStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('page.createTime')">
        <el-date-picker
          v-model="form.date"
          :editable="false"
          :end-placeholder="$t('title.starttime')"
          :start-placeholder="$t('title.endtime')"
          align="center"
          range-separator="~"
          type="daterange"
          unlink-panels
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item :label="$t('title.SendingTime')">
        <el-date-picker
          v-model="form.sendTime"
          :editable="false"
          :end-placeholder="$t('title.starttime')"
          :start-placeholder="$t('title.endtime')"
          align="center"
          range-separator="~"
          type="daterange"
          unlink-panels
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item label="">
        <el-button :loading="queryLoading" type="primary" @click="handleQuery">
          {{ $t('page.search') }}
        </el-button>
        <el-button @click="handleReset">{{ $t('page.reset') }}</el-button>
      </el-form-item>
    </el-form>
    <vxe-toolbar style="background-color: #e8f3ff;border: 1px solid #cce7ff;border-radius: 3px;margin-bottom: 6px;padding: 0 5px;">
      <template #buttons>
        <el-button type="primary" @click="handleExport">
          {{ $t('title.QueryResultExport') }}
        </el-button>
        <el-button @click="importDialogVisible = true">
          {{ $t('title.ManualImport') }}
        </el-button>
      </template>
    </vxe-toolbar>
    <vxe-table
      ref="table"
      :column-config="{ resizable: true }"
      :data="tableData"
      :loading="queryLoading"
      :max-height="maxHeight"
      :scroll-x="{ enabled: false }"
      :scroll-y="{ enabled: false }"
      :show-overflow="false"
      align="center"
    >
      <vxe-column :title="$t('title.Ticketno')" field="workNumber" min-width="240" />
      <vxe-column :title="$t('topic.PlatformOrderNumber')" field="orderNumber" min-width="240" />
      <vxe-column :title="$t('title.CustomerID')" field="customerId" min-width="160" />
      <vxe-column :title="$t('title.PlatformAccount')" field="bg" min-width="100" />
      <vxe-column :title="$t('title.Agent')" field="owner" min-width="80" />
      <vxe-column :title="$t('title.OrderCreationMethod')" field="workType" min-width="80" />
      <vxe-column :title="$t('title.SentStatus')" field="workStatus" min-width="80" />
      <vxe-column :title="$t('page.createTime')" field="createTime" min-width="150" />
      <vxe-column :title="$t('title.send') + $t('title.time')" field="sendTime" min-width="150" />
      <vxe-column :title="$t('title.EmailAddress')" field="email" min-width="240" />
    </vxe-table>
    <paging ref="pager" :pager="pager" end @update="handlePagerUpdate" />
    <import-dialog :visible.sync="importDialogVisible" @refresh="handleQuery" />
  </div>
</template>

<script>
import Paging from '@/components/Paging/index.vue'
import { debounceGetTableMaxHeight, ObjectToURL } from '@/utils'
import ImportDialog from './ImportDialog.vue'
import {
  exportQuestionnaire,
  getQuestionnaireWorkOrderList
} from '@/views/service-manage/questionnaire-work-order-manage/api'

export default {
  name: 'QuestionnaireWorkOrderManagement',
  components: { ImportDialog, Paging },
  data() {
    return {
      form: {
        workNumber: '',
        workType: '',
        workStatus: '',
        bg: '',
        date: [],
        sendTime: []
      },
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      tableData: [],
      queryLoading: false,
      maxHeight: 520,
      importDialogVisible: false,

      queryForm: {}
    }
  },
  computed: {
    queryParams() {
      return {
        ...this.form,
        ...this.pager,
        date: undefined,
        createTimeEnd: this.form.date?.[1],
        createTimeStart: this.form.date?.[0],
        sendTime: undefined,
        sendTimeEnd: this.form.sendTime?.[1],
        sendTimeStart: this.form.sendTime?.[0]
      }
    }
  },
  watch: {
    form: {
      handler() {
        this.queryChange = true
      },
      deep: true
    }
  },
  created() {
    this.handleQuery()
    this.debounceGetTableMaxHeight = debounceGetTableMaxHeight.bind(this)
    this.workTypeList = [
      { label: this.$t('route.SystemManual'), value: '1' },
      { label: this.$t('route.SimpleManual'), value: '2' }
    ]
    this.workStatusList = [
      { label: this.$t('tips.ToBeSend'), value: '1' },
      { label: this.$t('tips.Sent'), value: '2' },
      { label: this.$t('tips.Failedsend'), value: '3' }
    ]
    this.platFormAccountList = [
      { label: 'Dream Pairs', value: 'Dream Pairs' },
      { label: 'Nortiv8', value: 'Nortiv8' },
      { label: 'Bruno Marc', value: 'Bruno Marc' },
      { label: 'BURUDANI', value: 'BURUDANI' }
    ]
  },
  mounted() {
    this.debounceGetTableMaxHeight()
    window.addEventListener('resize', this.debounceGetTableMaxHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debounceGetTableMaxHeight)
  },
  methods: {
    async handleExport() {
      window.open(`${exportQuestionnaire}${ObjectToURL(this.queryForm)}`, '_blank')
    },
    async handleQuery() {
      if (new Date(this.queryParams.sendTimeEnd) < new Date(this.queryParams.createTimeStart)) {
        this.$message.warning(this.$t('title.SentTimeMustBeLaterThanCreationTime'))
        return
      }
      this.queryLoading = true
      if (this.queryChange) {
        this.pager.current = 1
        this.queryForm = { ...this.queryParams }
      }
      const { datas } = await getQuestionnaireWorkOrderList(this.queryParams).finally(() => {
        this.queryLoading = false
        this.queryChange = false
      })
      const { records, pager } = datas || {}
      this.tableData = records || []
      this.pager.total = pager?.total || 0
    },
    handleReset() {
      this.form = {
        workNumber: '',
        workType: '',
        bg: '',
        workStatus: '',
        date: [],
        sendTime: []
      }
      this.handleQuery()
    },
    handlePagerUpdate(val) {
      this.pager = val
      this.handleQuery()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
