import request from '@/utils/request'

export function getQuestionnaireWorkOrderList(params) {
  return request({
    url: `${process.env.VUE_APP_ERP_NEW_API}mos-web-core/Customer/getQuestionnaireByPage`,
    method: 'get',
    params
  })
}

export function checkFile(data) {
  return request({
    url: `${process.env.VUE_APP_ERP_NEW_API}mos-web-core/Customer/checkQuestionnaire`,
    method: 'post',
    data
  })
}

export function saveFile(data) {
  return request({
    url: `${process.env.VUE_APP_ERP_NEW_API}mos-web-core/Customer/saveQuestionnaire`,
    method: 'post',
    data
  })
}

export const exportQuestionnaire = `${process.env.VUE_APP_ERP_NEW_API}mos-web-core/Customer/exportQuestionnaire`
