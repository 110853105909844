<template>
  <el-dialog
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="$t('page.import')"
    :visible.sync="visible"
    center
    top="5vh"
    width="80%"
  >
    <el-steps :active="active" align-center finish-status="success" process-status="finish" style="margin-bottom: 20px">
      <el-step description="根据模板修改后上传" title="上传文件" />
      <el-step description="数据导入至服务器" title="执行导入" />
      <el-step v-if="!isError" description="完成数据批量导入" title="导入成功" />
      <el-step v-else description="修改文件后重新导入" status="error" title="导入失败" />
    </el-steps>
    <div v-if="!isFinished">
      <el-descriptions :colon="false" :column="1" title="填写导入问卷工单信息">
        <el-descriptions-item>
          请按照数据模板的格式准备导入数据，模板中的表头名称不可更改，表头行不能删除，单次导入的数据不超过200条。
        </el-descriptions-item>
        <el-descriptions-item>
          <el-link :href="templateUrl" :underline="false" type="primary">下载模板</el-link>
        </el-descriptions-item>
      </el-descriptions>
      <el-divider />
      <el-descriptions :colon="false" :column="1" title="上传填好的客服工单信息">
        <el-descriptions-item>
          文件后缀名必须为xls或xlsx，文件大小不超过10M。
        </el-descriptions-item>
        <el-descriptions-item>
          <upload
            ref="upload"
            :http-request="handleUpload"
            :limit="1"
            :size-limit="10 * 1024 * 1024"
            accept=".xls,.xlsx"
            list-type="text"
            style="display: inline-block"
            @remove="handleRemove"
          >
            <el-button type="text" @click.capture.native="handleImport">上传文件</el-button>
          </upload>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <el-descriptions v-if="isError" :colon="false" :column="1" style="margin-top: 40px" title="">
      <el-descriptions-item content-style="color: #13ce66" label="正确数据数量">
        {{ successCount }}条
      </el-descriptions-item>
      <el-descriptions-item content-style="color: #ff4949" label="异常数据数量">
        {{ errorCount }}条
        <span style="cursor: pointer; color: #409EFF" @click="handleDownloadErrorFile">下载异常数据重新导入</span>
      </el-descriptions-item>
    </el-descriptions>
    <el-divider class="error-info" />
    <template #footer>
      <el-button v-if="isError" @click="handleReset">重新导入</el-button>
      <el-button v-else :loading="uploading" type="primary" @click="handleNext">下一步</el-button>
    </template>
  </el-dialog>
</template>

<script>
import Upload from '@/components/Upload'
import { checkFile, saveFile } from './api'

export default {
  name: 'ImportDialog',
  components: {
    Upload
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      templateUrl: 'https://aliyun-oa-query-results-1509030252432744-oss-cn-hongkong.oss-cn-hongkong.aliyuncs.com/template/%E9%97%AE%E5%8D%B7%E5%B7%A5%E5%8D%95%E6%A8%A1%E6%9D%BF.xlsx',
      active: 0,
      file: null,
      uploading: false,
      errorCount: 0,
      successCount: 0,
      errorFileUrl: ''
    }
  },
  computed: {
    isUpload() {
      return this.active === 0
    },
    isError() {
      return this.active === 4
    },
    isFinished() {
      return this.active >= 3
    }
  },
  methods: {
    handleImport(e) {
      if (!this.isUpload) {
        e.stopPropagation()
      }
    },
    handleUpload({ file }) {
      this.file = file
      this.active++
    },
    handleRemove() {
      this.file = null
    },
    handleReset() {
      this.active = 0
      this.file = null
      this.$refs.upload?.clearFiles()
      this.successCount = 0
      this.errorCount = 0
      this.errorFileUrl = ''
    },
    async handleNext() {
      const active = this.active
      if (active >= 4) {
        return
      }
      if (active === 0) {
        if (!this.file) {
          this.$message.error('请上传文件')
          return
        }
        this.active++
      }
      if (active === 1) {
        const formData = new FormData()
        formData.append('file', this.file)
        this.uploading = true
        const { datas } = await checkFile(formData)
          .finally(() => {
            this.uploading = false
          })
        if (datas?.errorMessage || datas.errorUrl) {
          this.errorCount = datas?.errorCount
          this.errorFileUrl = datas?.errorUrl
          this.successCount = datas?.successCount
          this.active = 4
        } else {
          this.uploading = true
          const { msg } = await saveFile(datas).finally(() => {
            this.uploading = false
          })
          this.$message.success(msg)
          this.$emit('refresh')
          this.handleClose()
        }
      }
    },
    handleDownloadErrorFile() {
      if (!this.errorFileUrl) {
        this.$message.warning('没有错误数据')
        return
      }
      window.open(this.errorFileUrl)
    },
    handleClose() {
      this.handleReset()
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .error-info.el-divider--horizontal {
  margin-top: 21px
}

::v-deep .el-divider--horizontal {
  margin-top: 5px;
}
</style>
